import React, { ReactNode } from "react";
import { useKeycloak } from "src/providers/keycloak/KeycloakProvider";

export const ProtectedRoute = ({ children }: { children: ReactNode }) => {
    const { keycloak, initialized, loading } = useKeycloak();

    if (loading || !initialized) return null;
    if (keycloak.authenticated) return <>{children}</>;

    keycloak.login();

    return null;
};
