import Keycloak from "keycloak-js";

function getKeyCloak() {
    if (import.meta.env.VITE_CYPRESS === "true") {
        return {
            authenticated: true,
            hasRealmRole: () => {
                true;
            },
            async init() {
                return true;
            },
            async login() {
                return true;
            },
            async logout() {
                alert("logout");
                return true;
            },
            async updateToken() {
                return true;
            },
        } as unknown as Keycloak;
    } else {
        if (
            !import.meta.env.VITE_KEYCLOAK_URL ||
            !import.meta.env.VITE_KEYCLOAK_REALM ||
            !import.meta.env.VITE_KEYCLOAK_CLIENT
        ) {
            throw new Error(
                "Please configure keycloak in environment variables."
            );
        }

        return new Keycloak({
            url: import.meta.env.VITE_KEYCLOAK_URL,
            realm: import.meta.env.VITE_KEYCLOAK_REALM,
            clientId: import.meta.env.VITE_KEYCLOAK_CLIENT,
        });
    }
}

export default getKeyCloak();
