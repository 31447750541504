import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import KeycloakProvider from "./providers/keycloak/KeycloakProvider";
import App from "./App";
import "./i18n.config.ts";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

root.render(
    <KeycloakProvider>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </KeycloakProvider>
);
