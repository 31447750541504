import { type ChangeEventHandler, type ReactNode } from "react";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FilterAlt from "@mui/icons-material/FilterAlt";

type ContainerProps = {
    children: ReactNode;
};

type HeaderProps = {
    title: ReactNode;
    button: {
        to: string;
        label: ReactNode;
    };
};

type MainProps = {
    children: ReactNode;
};

type FilterControlProps = {
    label: ReactNode;
    onChange: ChangeEventHandler<HTMLInputElement>;
};

export const styled = {
    Container({ children }: ContainerProps) {
        return (
            <Container component={"section"} maxWidth="md">
                <Stack spacing={4}>{children}</Stack>
            </Container>
        );
    },

    Header({ title, button }: HeaderProps) {
        return (
            <Box component={"header"} sx={{ pt: 1 }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography
                        variant="h1"
                        data-testid="healthcare-provider-excerpts-title"
                    >
                        {title}
                    </Typography>
                    <Button
                        variant="contained"
                        size="large"
                        component={Link}
                        to={button.to}
                        data-testid="create-healthcare-provider-excerpts-button"
                    >
                        {button.label}
                    </Button>
                </Stack>
            </Box>
        );
    },

    Main({ children }: MainProps) {
        return (
            <Stack component="main" direction="column" spacing={0}>
                {children}
            </Stack>
        );
    },

    FilterControl({ label, onChange }: FilterControlProps) {
        return (
            <Box>
                <FormControl sx={{ width: "100%" }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-filter">
                        {label}
                    </InputLabel>
                    <OutlinedInput
                        data-testid="filter-input"
                        id="outlined-adornment-filter"
                        type="search"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton aria-label="filter" edge="end">
                                    <FilterAlt />
                                </IconButton>
                            </InputAdornment>
                        }
                        label={label}
                        onChange={onChange}
                    />
                </FormControl>
            </Box>
        );
    },
};
