import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ResultsDialogProps } from "./RevokeResultsDialog.types";

const ResultsDialog = ({
    open,
    results,
    onClose,
    navigateToPath = "/",
}: ResultsDialogProps) => {
    const { t } = useTranslation("common", {
        keyPrefix: "revoke-results-dialog",
    });
    const navigate = useNavigate();

    const handleCloseDialog = () => {
        onClose();
        navigate(navigateToPath);
    };
    const successResults = results?.filter((result) => result.successful);
    const unSuccessResults = results?.filter((result) => result.unsuccessful);

    return (
        <Dialog
            onClose={handleCloseDialog}
            open={open}
            maxWidth={"sm"}
            fullWidth
            data-testid="result"
        >
            <DialogTitle>
                <Typography variant="subtitle1">{t("title")}</Typography>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    {successResults?.length ? (
                        <Alert severity="success">
                            {t("ammount-of-successful-revokes", {
                                amount: successResults.length,
                            })}
                        </Alert>
                    ) : null}
                    {unSuccessResults?.length ? (
                        <Alert severity="error">
                            {t("ammount-of-unsuccessful-revokes", {
                                amount: unSuccessResults.length,
                            })}
                        </Alert>
                    ) : null}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog}>{t("ok")}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ResultsDialog;
