import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Typography } from "@mui/material";
import FieldPaper from "src/components/organisms/FieldPaper/FieldPaper";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import nl from "date-fns/locale/nl";
import { useValidationQuestionProvider } from "../../context/CreateValidatedQuestionContext";
import { format, setMinutes } from "date-fns";

function DateComponent({ step = 999 }) {
    const { t } = useTranslation();
    const validatedQuestions = useValidationQuestionProvider();
    const [issueAt, setIssueAt] = useState(formatDate(new Date()));
    const indicators = validatedQuestions.form.watch("indicators") ?? [];

    useEffect(() => {
        handleDateChange(issueAt);
    }, [issueAt, indicators]);

    const handleDateChange = (date: string) => {
        if (!date) return;

        validatedQuestions.form.setValue(
            "indicators",
            indicators.map((indicator) => ({
                ...indicator,
                issueAt: date,
            }))
        );
    };

    const handleDatePickerOnChange = (date: unknown) => {
        if (!isDate(date)) return;
        setIssueAt(formatDate(date));
    };

    return (
        <FieldPaper
            title={
                <Typography variant="h6">
                    {step}. {t("validated-question-create.date.title")}
                </Typography>
            }
        >
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={nl}
            >
                <DateTimePicker
                    views={["year", "month", "day", "hours"]}
                    defaultValue={new Date()}
                    onChange={handleDatePickerOnChange}
                />
            </LocalizationProvider>
        </FieldPaper>
    );
}

function isDate(input: unknown): input is Date {
    return input instanceof Date;
}

function formatDate(date: Date) {
    return format(setMinutes(date, 0), "yyyy-MM-dd'T'HH:mm");
}

export default DateComponent;
