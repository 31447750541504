import type { ApolloError } from "@apollo/client";
import { useEffect, useState } from "react";
import { gitserviceClient } from "src/graphql/client.gitservice";
import type {
    GetExchangeProfilesQuery,
    IndicatorInput,
} from "src/graphql/generated/api/graphql";
import { useGetExchangeProfilesQuery } from "src/graphql/generated/api/graphql";
import type { GetIndicatorsForRepoQuery } from "src/graphql/generated/gitservice/graphql";
import { GetIndicatorsForRepoDocument } from "src/graphql/generated/gitservice/graphql";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

export const useCreateValidatedQuestions = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<ApolloError>();
    const [currentExchangeProfile, setCurrentExchangeProfile] =
        useState<GetExchangeProfilesQuery["getExchangeProfiles"][number]>();
    const { data: exchangeProfiles } = useGetExchangeProfilesQuery();
    const [indicators, setIndicators] = useState<
        GetIndicatorsForRepoQuery["getIndicatorsForRepo"]
    >([]);
    const { t } = useTranslation("common", {
        keyPrefix: "validated-question-create",
    });

    const schema = yup
        .object({
            exchangeProfile: yup
                .string()
                .required(t("exchangeProfile.errors.required") ?? void 0),
            version: yup
                .string()
                .required(t("version.errors.required") ?? void 0),
            indicators: yup
                .array()
                .min(1, t("indicators.errors.required") ?? void 0),
            subjectDids: yup
                .array()
                .of(yup.string())
                .required(t("subject.errors.required") ?? void 0),
        })
        .required();

    const form = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            version: "",
            exchangeProfile: "",
            indicators: [] as IndicatorInput[],
            subjectDids: [],
        },
    });

    useEffect(() => {
        if (!exchangeProfiles || !form.watch("exchangeProfile")) return;

        const profile = exchangeProfiles.getExchangeProfiles.find(
            (item) => item.id === form.watch("exchangeProfile")
        );

        setCurrentExchangeProfile(profile);
    }, [form.watch("exchangeProfile"), exchangeProfiles]);

    useEffect(() => {
        if (!exchangeProfiles || !form.watch("exchangeProfile")) return;

        const profile = exchangeProfiles.getExchangeProfiles.find(
            (item) => item.id === form.watch("exchangeProfile")
        );

        setCurrentExchangeProfile(profile);

        if (!profile) return;

        setLoading(true);
        setError(undefined);

        // clear selected indicators
        form.setValue("indicators", []);

        const fetchData = async () => {
            const response = await gitserviceClient
                .query<GetIndicatorsForRepoQuery>({
                    query: GetIndicatorsForRepoDocument,
                    variables: {
                        repositoryUrl: profile.url,
                        branchOrTag: form.watch("version"),
                    },
                })
                .catch((err) => {
                    setError(err);
                    setIndicators([]);
                });

            if (response) {
                setIndicators(response.data.getIndicatorsForRepo || []);
            }

            setLoading(false);
        };

        fetchData();
    }, [form.watch("version")]);

    return {
        form,
        exchangeProfiles,
        indicators,
        loading,
        error,
        profile: currentExchangeProfile,
    };
};
