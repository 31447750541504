import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { Input } from "@mui/material";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useCreateUser } from "./context/CreateUserHook";
import { ErrorMessage } from "@hookform/error-message";
import { useCreateUserMutation } from "@/src/graphql/generated/api/graphql";
import { useNavigate } from "react-router-dom";

const CreateUserTemplate = () => {
    const { t } = useTranslation("common", {
        keyPrefix: "create-user",
    });

    const createUser = useCreateUser();

    const [loading, setLoading] = useState(false);

    const { form } = createUser;
    const user = form.watch("user");

    const navigate = useNavigate();

    const onSubmit = form.handleSubmit(
        () => {
            setLoading(true);
            createUserMutation();
        },
        (error) => {
            console.error(error);
        }
    );

    const [createUserMutation] = useCreateUserMutation({
        variables: {
            input: {
                firstName: user?.firstName,
                lastName: user?.lastName,
                email: user?.email,
                temporaryPassword: user?.password,
            },
        },
        refetchQueries: ["GetUsers"],
        onCompleted: (data) => {
            setLoading(false);
            navigate("/users");
        },
        onError: (error) => {
            console.error(error);
            setLoading(false);
        },
    });

    return (
        <div>
            <form onSubmit={onSubmit} data-testid="form">
                <Stack direction="row" marginTop={3}>
                    <FormControl>
                        <InputLabel htmlFor="firstName">Voornaam</InputLabel>
                        <Input
                            {...form.register("user.firstName")}
                            id="firstname"
                        />
                        <FormHelperText>
                            <ErrorMessage
                                errors={form.formState.errors}
                                name="user.firstName"
                            />
                        </FormHelperText>
                    </FormControl>
                </Stack>
                <Stack direction="row" marginTop={3}>
                    <FormControl>
                        <InputLabel htmlFor="lastName">Achternaam</InputLabel>
                        <Input
                            {...form.register("user.lastName")}
                            id="lastName"
                        />
                        <FormHelperText>
                            <ErrorMessage
                                errors={form.formState.errors}
                                name="user.lastName"
                            />
                        </FormHelperText>
                    </FormControl>
                </Stack>
                <Stack direction="row" marginTop={3}>
                    <FormControl>
                        <InputLabel htmlFor="email">Email</InputLabel>
                        <Input {...form.register("user.email")} id="email" />
                        <FormHelperText>
                            <ErrorMessage
                                errors={form.formState.errors}
                                name="user.email"
                            />
                        </FormHelperText>
                    </FormControl>
                </Stack>
                <Stack direction="row" marginTop={3}>
                    <FormControl>
                        <InputLabel htmlFor="password">Wachtwoord</InputLabel>
                        <Input
                            {...form.register("user.password")}
                            id="password"
                            type="password"
                        />
                        <FormHelperText>
                            <ErrorMessage
                                errors={form.formState.errors}
                                name="user.password"
                            />
                        </FormHelperText>
                    </FormControl>
                </Stack>
                <Stack direction="row" marginTop={3}>
                    <Box flex="0 1 auto" />
                    <LoadingButton
                        type="submit"
                        loading={loading}
                        variant="contained"
                        color="primary"
                        onClick={onSubmit}
                        disabled={!form.formState.isValid}
                        data-testid="submit-button"
                    >
                        {t("submit")}
                    </LoadingButton>
                </Stack>
            </form>
        </div>
    );
};

export default CreateUserTemplate;
