import { RouterProvider } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "@mui/material";
import { nlNL as theme } from "@tribe/react-mui-overheid-thema";
import { router } from "./router";
import { useKeycloak } from "./providers/keycloak/KeycloakProvider";
import fetchWithCredentials from "./lib/apollo/fetchWithCredentials";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { apollo } from "./lib/apollo/client";
import "@tribe/react-mui-overheid-thema/styles/style.scss";

function App() {
    const { keycloak } = useKeycloak();

    const uploadLink = createUploadLink({
        uri: import.meta.env.VITE_API_GRAPHQL_ENDPOINT,
        fetch: (uri, options) =>
            fetchWithCredentials({ keycloak, uri, options }),
    });

    const client = apollo.getClient({
        apolloOptions: {
            link: uploadLink,
        },
    });

    return (
        <ApolloProvider client={client}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <RouterProvider router={router} />
            </ThemeProvider>
        </ApolloProvider>
    );
}

export default App;
