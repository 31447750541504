import { Navigate, createBrowserRouter } from "react-router-dom";
import { ProtectedRoute } from "./middleware/ProtectedRoute";
import DashboardRoot from "../components/templates/Dashboard/Dashboard";
import ErrorRoot from "../components/templates/Error/Error";
import CreateValidatedQuestion from "../components/pages/CreateValidatedQuestion";
import ValidatedQuestionsBySubjectDidOverview from "../components/pages/ValidatedQuestionsBySubjectDidOverview";
import CreateHealthcareProviderExcerpt from "@/src/components/pages/CreateHealthcareProviderExcerpt";
import HealthcareProviderExcerptOverview from "@/src/components/pages/HealthcareProviderExcerptOverview";
import SubjectDIDsOverview from "@/src/components/pages/SubjectDIDsOverview";
import UsersOverview from "@/src/components/pages/UsersOverview";
import CreateUser from "@/src/components/pages/CreateUser";
import CreateSubjectDID from "@/src/components/pages/CreateSubjectDID";
import EditSubjectDID from "@/src/components/pages/EditSubjectDID";

export const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <ProtectedRoute>
                <DashboardRoot />
            </ProtectedRoute>
        ),
        children: [
            {
                path: "/",
                element: <Navigate to="/validated-questions" replace />,
            },
            {
                path: "/validated-questions",
                element: <ValidatedQuestionsBySubjectDidOverview />,
            },
            {
                path: "/validated-questions/new",
                element: <CreateValidatedQuestion />,
            },
            {
                path: "/healthcare-provider-excerpts",
                element: <HealthcareProviderExcerptOverview />,
            },
            {
                path: "/healthcare-provider-excerpts/new",
                element: <CreateHealthcareProviderExcerpt />,
            },
            {
                path: "/subject-dids",
                element: <SubjectDIDsOverview />,
            },
            {
                path: "/subject-dids/new",
                element: <CreateSubjectDID />,
            },
            {
                path: "/subject-dids/:id",
                element: <EditSubjectDID />,
            },
            {
                path: "/users",
                element: <UsersOverview />,
            },
            {
                path: "/users/new",
                element: <CreateUser />,
            },
        ],
    },
    {
        path: "*",
        element: <ErrorRoot />,
    },
]);
