import { Alert, Box, TableContainer, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import FieldPaper from "src/components/organisms/FieldPaper/FieldPaper";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { IndicatorsPropTypes } from "./Indicators.types";
import { useValidationQuestionProvider } from "../../context/CreateValidatedQuestionContext";

import { ErrorMessage } from "@hookform/error-message";
import { GetIndicatorsForRepoQuery } from "../../../../../graphql/generated/gitservice/graphql";

const Indicators = ({ step }: IndicatorsPropTypes) => {
    const validatedQuestions = useValidationQuestionProvider();
    const fieldName = "indicators";

    const {
        control,
        formState: { errors },
    } = useFormContext();

    const { t } = useTranslation();

    const columns: GridColDef[] = [
        {
            field: "name",
            headerName:
                t("validated-question-create.indicators.select-all") || "",
            width: 200,
        },
    ];

    return (
        <FieldPaper
            title={
                <Typography variant="h6">
                    {step}. {t("validated-question-create.indicators.title")}
                </Typography>
            }
        >
            {validatedQuestions.error ? (
                <Alert severity="error">
                    {validatedQuestions.error.message}
                </Alert>
            ) : validatedQuestions.form.watch("version") ? (
                validatedQuestions.indicators.length === 0 &&
                !validatedQuestions.loading ? (
                    <Alert severity="error">
                        {t("validated-question-create.indicators.no-results")}
                    </Alert>
                ) : (
                    <Box sx={{ m: -3 }}>
                        <TableContainer data-testid="indicator-table">
                            {errors["indicators"] && (
                                <Alert severity="error" sx={{ m: 2 }}>
                                    <ErrorMessage
                                        errors={errors}
                                        name="indicators"
                                    />
                                </Alert>
                            )}
                            <Controller
                                name={fieldName}
                                control={control}
                                render={({ field }) => (
                                    <DataGrid
                                        loading={validatedQuestions.loading}
                                        density="compact"
                                        rows={validatedQuestions.indicators}
                                        columns={columns}
                                        pageSizeOptions={[100]}
                                        getRowId={(row) => row.path}
                                        checkboxSelection
                                        rowSelectionModel={field.value.map(
                                            (
                                                indicator: GetIndicatorsForRepoQuery["getIndicatorsForRepo"][number]
                                            ) => indicator.path
                                        )}
                                        onRowSelectionModelChange={(
                                            selectedIndicators
                                        ) => {
                                            const selected =
                                                validatedQuestions.indicators
                                                    .filter((indicator) =>
                                                        selectedIndicators.includes(
                                                            indicator.path
                                                        )
                                                    )
                                                    .map((indicator) => {
                                                        return {
                                                            name: indicator.name,
                                                            path: indicator.path,
                                                        };
                                                    });

                                            field.onChange(selected);
                                        }}
                                    />
                                )}
                            />
                        </TableContainer>
                    </Box>
                )
            ) : (
                <Alert severity="info">
                    {t("validated-question-create.indicators.empty-state")}
                </Alert>
            )}
        </FieldPaper>
    );
};

export default Indicators;
