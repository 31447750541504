import { useValidationQuestionProvider } from "./context/CreateValidatedQuestionContext";
import { Stack, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircularProgress from "@mui/material/CircularProgress";
import CodeBlock from "src/components/organisms/CodeBlock/CodeBlock";
import { useTranslation } from "react-i18next";
import { useGetCredentialDetailsForIndicatorsQuery } from "src/graphql/generated/api/graphql";
import { uniquePropFrom } from "src/lib/uniquePropFrom/uniquePropFrom";
import InfoBlock from "src/components/atoms/InfoBlock/InfoBlock";

const InfoBox = () => {
    const validatedQuestions = useValidationQuestionProvider();
    const { profile } = validatedQuestions;
    const version = validatedQuestions.form.watch("version");
    const indicators = validatedQuestions.form.watch("indicators");
    const { t } = useTranslation();

    const { data, loading } = useGetCredentialDetailsForIndicatorsQuery({
        variables: {
            repositoryUrl: profile?.url || "",
            indicators: indicators || [],
            branchOrTag: version,
        },
    });

    const { getCredentialDetailsForIndicators } = data || {};

    if (!getCredentialDetailsForIndicators?.length) return null;
    if (loading) return <CircularProgress />;
    return (
        <div>
            <Stack spacing={2}>
                <Typography>{t("info-box.instruction")}</Typography>
                {profile?.name && (
                    <InfoBlock
                        label={t("info-box.exchange-profile")}
                        value={profile.name}
                    />
                )}
                {indicators && (
                    <InfoBlock
                        testId="issue-at-date"
                        label={t("info-box.date")}
                        value={
                            uniquePropFrom(
                                indicators,
                                ({ issueAt }) => issueAt
                            ).map((issueAtDate = "") =>
                                issueAtDate.replace("T", " ")
                            )[0] || t("info-box.no-date")
                        }
                    />
                )}
                {version && (
                    <InfoBlock label={t("info-box.version")} value={version} />
                )}
                {getCredentialDetailsForIndicators.length ? (
                    <Typography>
                        <strong>{t("info-box.indicators")} :</strong>
                    </Typography>
                ) : null}
                {getCredentialDetailsForIndicators &&
                    getCredentialDetailsForIndicators.map((data) => (
                        <Accordion key={data.queryName}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="indicator"
                                id="indicator"
                            >
                                <Typography>
                                    {data.indicator?.name || ""}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    <Stack spacing={2}>
                                        <InfoBlock
                                            label={t("info-box.ontology")}
                                            value={data.ontologies
                                                .map(
                                                    (ontology) => ontology.title
                                                )
                                                .join(", ")}
                                        />

                                        <InfoBlock
                                            label={t("info-box.description")}
                                            value={data.queryDescription}
                                        />
                                        <a
                                            href={data.profileUrl}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {t("info-box.publication-profile")}
                                        </a>
                                        <div>
                                            <Typography>
                                                <strong>
                                                    {t("info-box.sparql")}
                                                </strong>
                                            </Typography>
                                            <CodeBlock
                                                inline={false}
                                                language={"sparql"}
                                            >
                                                {data.sparql}
                                            </CodeBlock>
                                        </div>
                                    </Stack>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    ))}
            </Stack>
        </div>
    );
};

export default InfoBox;
