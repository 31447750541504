import type Keycloak from "keycloak-js";

const fetchWithCredentials = async ({
    keycloak,
    uri,
    options: _options,
}: {
    keycloak: Keycloak;
    uri: URL | RequestInfo;
    options?: RequestInit;
}) => {
    const options: RequestInit = {
        ...(_options || {}),
    };

    if (keycloak.token) {
        options.headers = {
            ...options.headers,
            Authorization: `Bearer ${keycloak.token}`,
        };
    }

    try {
        await keycloak.updateToken(5);
    } catch (error) {
        console.error(error);
        keycloak.login();
    }

    return fetch(uri, options);
};

export default fetchWithCredentials;
