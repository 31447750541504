import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import type { CredentialResultsDialogProps } from "./CredentialResultsDialog.types";
import { ErrorBox, SuccessBox } from "./CredentialResultsDialog.styles";

const CredentialResultsDialog = ({
    open,
    results,
    onClose,
}: CredentialResultsDialogProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleCloseDialog = () => {
        onClose();
        navigate("/");
    };
    const successResults = results?.filter((result) => result.successful);
    const unSuccessResults = results?.filter((result) => result.unsuccessful);

    return (
        <Dialog
            onClose={handleCloseDialog}
            open={open}
            maxWidth={"md"}
            fullWidth
            data-testid="result"
        >
            <DialogTitle>
                <Typography>{t("credentials-results-dialog.title")}</Typography>
            </DialogTitle>
            <DialogContent>
                {successResults?.length ? (
                    <List
                        subheader={
                            <SuccessBox>
                                <strong>
                                    {t(
                                        "credentials-results-dialog.successfull-results"
                                    )}
                                </strong>
                            </SuccessBox>
                        }
                    >
                        {successResults.map((item, index) => (
                            <ListItem disablePadding key={index}>
                                {item.indicator.name}
                            </ListItem>
                        ))}
                    </List>
                ) : null}
                {unSuccessResults?.length ? (
                    <List
                        subheader={
                            <ErrorBox>
                                <strong>
                                    {t(
                                        "credentials-results-dialog.unsuccessfull-results"
                                    )}
                                </strong>
                            </ErrorBox>
                        }
                    >
                        {unSuccessResults.map((item, index) => (
                            <ListItem disablePadding key={index}>
                                {item.indicator.name}
                            </ListItem>
                        ))}
                    </List>
                ) : null}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog}>{t("general.oke")}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default CredentialResultsDialog;
