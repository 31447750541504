import type { ChangeEvent, Key } from "react";
import { Fragment, memo, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { styled } from "./ValidatedQuestionsBySubjectDidOverview.styles";
import {
    type GetIssuedCredentialsBySubjectDidForOverviewQuery,
    type RevokeCredentialsMutation,
} from "@/src/graphql/generated/api/graphql";
import RevokeResultsDialog from "../../organisms/RevokeResultsDialog/RevokeResultsDialog";
import ConfirmRevoke from "../../organisms/ConfirmRevoke/ConfirmRevoke";
import type { SubjectDidsForOverview } from "@/src/lib/mapValidatedQuestionsBySubjectDidByExchangeProfile/mapValidatedQuestionsBySubjectDidByExchangeProfile";
import SubjectDidFilter from "../../organisms/SelectSubjectDid/SelectSubjectDid";

export type IndicatorForOverview = {
    id: any;
    name: string;
    status: string;
    version?: string | null;
};

export type ExchangeProfileForOverview = {
    id: any;
    name: string;
    indicators: IndicatorForOverview[];
};

export interface ValidatedQuestionsOverviewProps {
    loading?: boolean;
    subjectDids: SubjectDidsForOverview[];
    indicators: GetIssuedCredentialsBySubjectDidForOverviewQuery["getIssuedVerifiableQueryCredentials"];
    onRevoke: (indicators: IndicatorForOverview[]) => void;
    onFilter: (filter: Key[]) => void;
    filter: Key[];
    revokeResults?: RevokeCredentialsMutation["revokeCredentials"];
    onCloseRevokeResults: () => void;
    openRevokeResults?: boolean;
}

function ValidatedQuestionsOverview({
    loading = false,
    subjectDids = [],
    indicators: _indicators = [],
    onRevoke = () => {},
    onFilter = () => {},
    filter = [],
    revokeResults = [],
    openRevokeResults = false,
    onCloseRevokeResults,
}: ValidatedQuestionsOverviewProps) {
    const { t } = useTranslation("common", {
        keyPrefix: "validated-questions",
    });

    const [selected, setSelected] = useState<string[]>([]);

    const [toRevoke, setToRevoke] = useState<IndicatorForOverview[]>([]);

    const handleRevoke = (indicators: IndicatorForOverview[]) => {
        setSelected((prevState) =>
            prevState.filter((id) => !indicators.map((i) => i.id).includes(id))
        );

        setToRevoke(indicators);
    };

    //
    // SubjectDid Checkboxes
    // -------------------------------------------------------------------------

    function isSubjectDidChecked(subjectDid: {
        exchangeProfiles: { indicators: { id: string }[] }[];
    }) {
        return subjectDid.exchangeProfiles.every((exchangeProfile) =>
            exchangeProfile.indicators.every((indicatorId) =>
                selected.includes(indicatorId.id)
            )
        );
    }

    function isSubjectDidIndeterminate(subjectDid: {
        exchangeProfiles: { indicators: { id: string }[] }[];
    }) {
        return subjectDid.exchangeProfiles.some((exchangeProfile) =>
            exchangeProfile.indicators.some((indicatorId) =>
                selected.includes(indicatorId.id)
            )
        );
    }

    function handleSubjectDidOnChange(subjectDid: {
        exchangeProfiles: { indicators: { id: string }[] }[];
    }) {
        return (event: ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked) {
                setSelected((prevSelection) => [
                    ...prevSelection,
                    ...subjectDid.exchangeProfiles
                        .map((ep) => ep.indicators.map((i) => i.id))
                        .flat(),
                ]);
            } else {
                setSelected((prevSelection) =>
                    prevSelection.filter((id) =>
                        subjectDid.exchangeProfiles.every((ep) =>
                            ep.indicators.every((i) => i.id !== id)
                        )
                    )
                );
            }
        };
    }

    //
    // ExchangeProfile Checkboxes
    // -------------------------------------------------------------------------

    function isExchangeProfileChecked(exchangeProfile: {
        indicators: { id: string }[];
    }) {
        return exchangeProfile.indicators.every((indicatorId) =>
            selected.includes(indicatorId.id)
        );
    }

    function isExchangeProfileIndeterminate(exchangeProfile: {
        indicators: { id: string }[];
    }) {
        return exchangeProfile.indicators.some((indicatorId) =>
            selected.includes(indicatorId.id)
        );
    }

    function handleExchangeProfileOnChange(exchangeProfile: {
        indicators: { id: string }[];
    }) {
        return (event: ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked) {
                setSelected((prevSelection) => [
                    ...prevSelection,
                    ...exchangeProfile.indicators.map((i) => i.id),
                ]);
            } else {
                setSelected((prevSelection) =>
                    prevSelection.filter(
                        (id) =>
                            !exchangeProfile.indicators.find((i) => i.id === id)
                    )
                );
            }
        };
    }

    //
    // Indicator Checkboxes
    // -------------------------------------------------------------------------

    const selectIndicator = (indicator: IndicatorForOverview) => {
        setSelected((prevState) => [...prevState, indicator.id]);
    };

    const deselectIndicator = (indicator: IndicatorForOverview) => {
        setSelected((prevState) =>
            prevState.filter((id) => id !== indicator.id)
        );
    };

    const toggleIndicator =
        (indicator: IndicatorForOverview) =>
        ({ target: { checked } }: ChangeEvent<HTMLInputElement>): any =>
            checked ? selectIndicator(indicator) : deselectIndicator(indicator);

    //
    // Bulk Menu Actions
    // -------------------------------------------------------------------------

    const revokeAllSelected = () => {
        const indicators = _indicators.filter((indicator) =>
            selected.includes(indicator.id)
        );

        handleRevoke(
            indicators.map((x) => ({
                id: x.id,
                name: x.indicatorName,
                status: x.status,
                version: x.tagOrBranch,
            }))
        );
    };

    const cancelSelection = () => {
        setSelected([]);
    };

    //
    // SubjectDidFilter
    // -------------------------------------------------------------------------

    function handleFilterChange(event: { target: { value: Key[] } }) {
        onFilter(event.target.value);
    }

    return (
        <>
            <styled.Container>
                <styled.Header
                    title={t("title")}
                    button={{
                        to: "new",
                        label: t("new-validated-question"),
                    }}
                />
                <styled.Main>
                    <SubjectDidFilter
                        value={filter}
                        onChange={handleFilterChange}
                    />

                    {loading && <styled.Loading />}

                    {subjectDids.map((subjectDid, index, array) => (
                        <Box key={subjectDid.id}>
                            <styled.ExchangeProfileControl
                                label={subjectDid.name}
                                variant="h2"
                                checkbox={{
                                    checked: isSubjectDidChecked(subjectDid),
                                    indeterminate:
                                        isSubjectDidIndeterminate(subjectDid),
                                    onChange:
                                        handleSubjectDidOnChange(subjectDid),
                                }}
                            />
                            {subjectDid.exchangeProfiles.map(
                                (exchangeProfile) => (
                                    <Fragment key={exchangeProfile.name}>
                                        <styled.ExchangeProfileControl
                                            label={exchangeProfile.name}
                                            checkbox={{
                                                checked:
                                                    isExchangeProfileChecked(
                                                        exchangeProfile
                                                    ),
                                                indeterminate:
                                                    isExchangeProfileIndeterminate(
                                                        exchangeProfile
                                                    ),
                                                onChange:
                                                    handleExchangeProfileOnChange(
                                                        exchangeProfile
                                                    ),
                                            }}
                                            pl={3}
                                            pt={1}
                                            variant="h4"
                                        />
                                        <styled.IndicatorRowGroup>
                                            {exchangeProfile.indicators.map(
                                                (indicator) => (
                                                    <styled.IndicatorControl
                                                        key={indicator.id}
                                                        checked={selected.includes(
                                                            indicator.id
                                                        )}
                                                        onChange={toggleIndicator(
                                                            indicator
                                                        )}
                                                        label={indicator.name}
                                                        button={{
                                                            onClick:
                                                                handleRevoke.bind(
                                                                    0,
                                                                    [indicator]
                                                                ),
                                                            label: t("revoke"),
                                                        }}
                                                        status={
                                                            indicator.status
                                                        }
                                                        version={
                                                            indicator.tagOrBranch
                                                        }
                                                    />
                                                )
                                            )}
                                        </styled.IndicatorRowGroup>
                                    </Fragment>
                                )
                            )}
                            <styled.EchangeProfileDivider
                                visible={index < array.length - 1}
                            />
                        </Box>
                    ))}
                </styled.Main>
                <styled.Footer
                    visible={Boolean(selected.length)}
                    revokeButton={{
                        label: t("revoke-selected"),
                        onClick: revokeAllSelected,
                    }}
                    cancelSelectionButton={{
                        label: t("cancel-selection"),
                        onClick: cancelSelection,
                    }}
                />
            </styled.Container>

            <RevokeResultsDialog
                open={openRevokeResults}
                results={revokeResults}
                onClose={onCloseRevokeResults}
            />
            <ConfirmRevoke
                open={toRevoke.length > 0}
                indicators={toRevoke}
                onClose={({ confirmed }) => {
                    if (confirmed) onRevoke(toRevoke);
                    setToRevoke([]);
                }}
            />
        </>
    );
}

export default memo(ValidatedQuestionsOverview);
