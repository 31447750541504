import { useState } from "react";
import { Alert, Box, Container, Grid, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ExchangeProfiles from "./steps/ExchangeProfiles/ExchangeProfiles";
import Indicators from "./steps/Indicators/Indicators";
import Subject from "./steps/Subjects/Subject";
import Version from "./steps/Version/Version";
import DateComponent from "./steps/Date/Date";
import { ButtonSpacing } from "./CreateValidatedQuestion.styles";
import { useTranslation } from "react-i18next";
import { useCreateValidatedQuestions } from "./context/CreateValidatedQuestionsHook";
import { CreateValidationQuestionProvider } from "./context/CreateValidatedQuestionContext";
import type { ApolloError } from "@apollo/client";
import type { IssueCredentialsForIndicatorsMutation } from "@/src/graphql/generated/api/graphql";
import { useIssueCredentialsForIndicatorsMutation } from "@/src/graphql/generated/api/graphql";
import isString from "@/src/lib/isString/isString";
import castNumber from "@/src/lib/castNumber/castNumber";
import InfoBox from "./InfoBox";
import StickyContent from "src/components/atoms/StickyContent/StickyContent";
import FieldPaper from "src/components/organisms/FieldPaper/FieldPaper";
import CredentialResultsDialog from "../../organisms/CredentialResultsDialog/CredentialResultsDialog";

const CreateValidatedQuestion = () => {
    const { t } = useTranslation();

    const validatedQuestions = useCreateValidatedQuestions();

    const [loading, setLoading] = useState(false);

    const [submitted, setSubmitted] = useState(false);

    const [error, setError] = useState<ApolloError>();

    const [credentialResults, setCredentialResults] =
        useState<
            IssueCredentialsForIndicatorsMutation["issueCredentialsForIndicators"]
        >();

    const [openResultsDialog, setOpenResultsDialog] = useState(false);

    const [issueCredentialsForIndicatorsMutation] =
        useIssueCredentialsForIndicatorsMutation();

    const onSubmit = validatedQuestions.form.handleSubmit(async (data) => {
        setLoading(true);

        const response = await issueCredentialsForIndicatorsMutation({
            variables: {
                repositoryUrl: validatedQuestions.profile?.url ?? "",
                branchOrTag: data.version,
                indicators: data.indicators || [],
                subjectIds: (data.subjectDids || [])
                    .filter(isString)
                    .map(castNumber),
            },
            refetchQueries: ["GetIssuedCredentialsForOverviewQuery"],
        }).catch((err) => {
            setError(err);
            return null;
        });

        setLoading(false);

        if (response) {
            setSubmitted(true);
            setOpenResultsDialog(true);
            setCredentialResults(response?.data?.issueCredentialsForIndicators);
        }
    });

    const handleCloseDialog = () => {
        setOpenResultsDialog(false);
    };

    return (
        <CreateValidationQuestionProvider value={validatedQuestions}>
            <form onSubmit={onSubmit} data-testid="form">
                <Container>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={4}>
                                <Stack spacing={3}>
                                    <Subject step={1} />
                                    <ExchangeProfiles step={2} />
                                    <Version step={3} />
                                    <Indicators step={4} />
                                    <DateComponent step={5} />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <StickyContent>
                                    <FieldPaper
                                        title={
                                            <Typography variant="h6">
                                                {t(
                                                    "validated-question-create.general-info"
                                                )}
                                            </Typography>
                                        }
                                    >
                                        <Box sx={{ p: 0 }}>
                                            <InfoBox />
                                            <Stack spacing={3}>
                                                {!validatedQuestions.form.watch(
                                                    "exchangeProfile"
                                                ) && (
                                                    <Alert severity="info">
                                                        {t(
                                                            "validated-question-create.fill-in-exchangeProfile"
                                                        )}
                                                    </Alert>
                                                )}

                                                {!validatedQuestions.form.watch(
                                                    "version"
                                                ) && (
                                                    <Alert severity="info">
                                                        {t(
                                                            "validated-question-create.fill-in-version"
                                                        )}
                                                    </Alert>
                                                )}

                                                {!validatedQuestions.form.watch(
                                                    "indicators"
                                                ) ||
                                                    (validatedQuestions.form.watch(
                                                        "indicators"
                                                    )?.length === 0 && (
                                                        <Alert severity="info">
                                                            {t(
                                                                "validated-question-create.fill-in-indicators"
                                                            )}
                                                        </Alert>
                                                    ))}

                                                {error && (
                                                    <Alert
                                                        severity="error"
                                                        data-testid="error"
                                                    >
                                                        {error.message}
                                                    </Alert>
                                                )}

                                                <CredentialResultsDialog
                                                    onClose={handleCloseDialog}
                                                    open={openResultsDialog}
                                                    results={credentialResults}
                                                />

                                                {submitted ? (
                                                    <ButtonSpacing>
                                                        <Alert severity="success">
                                                            {t(
                                                                "validated-question-create.success"
                                                            )}
                                                        </Alert>
                                                    </ButtonSpacing>
                                                ) : (
                                                    <ButtonSpacing>
                                                        <LoadingButton
                                                            loading={loading}
                                                            variant="contained"
                                                            type="submit"
                                                        >
                                                            {t(
                                                                "validated-question-create.submit"
                                                            )}
                                                        </LoadingButton>
                                                    </ButtonSpacing>
                                                )}
                                            </Stack>
                                        </Box>
                                    </FieldPaper>
                                </StickyContent>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </form>
        </CreateValidationQuestionProvider>
    );
};

export default CreateValidatedQuestion;
