import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  LocalDateTime: { input: any; output: any; }
};

export type DetailedIndicatorDto = {
  __typename?: 'DetailedIndicatorDto';
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
  sparql: Scalars['String']['output'];
};

export type GitOwlInput = {
  branchOrTag?: InputMaybe<Scalars['String']['input']>;
  owlPath: Scalars['String']['input'];
  repositoryUrl: Scalars['String']['input'];
};

export type GitRemoteDto = {
  __typename?: 'GitRemoteDto';
  repositoryUrl: Scalars['String']['output'];
  tagOrBranchName?: Maybe<Scalars['String']['output']>;
};

export type GitRemoteInput = {
  branchOrTag?: InputMaybe<Scalars['String']['input']>;
  contentPath?: InputMaybe<Scalars['String']['input']>;
  repositoryUrl: Scalars['String']['input'];
};

export type GitRepositoryDto = {
  __typename?: 'GitRepositoryDto';
  commitDate?: Maybe<Scalars['LocalDateTime']['output']>;
  commitId?: Maybe<Scalars['String']['output']>;
  remote: GitRemoteDto;
  startingDate?: Maybe<Scalars['String']['output']>;
};

export type IndicatorDto = {
  __typename?: 'IndicatorDto';
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
};

export type MarkdownDto = {
  __typename?: 'MarkdownDto';
  content: Scalars['String']['output'];
  path: Scalars['String']['output'];
};

export type OutlineItemDto = {
  __typename?: 'OutlineItemDto';
  children: Array<OutlineItemDto>;
  label: Scalars['String']['output'];
  path?: Maybe<Scalars['String']['output']>;
};

export type OwlDto = {
  __typename?: 'OwlDto';
  url: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  /** Get a detailed indicator with it's SPARQL contents and further details for a given path */
  getIndicatorByPath?: Maybe<DetailedIndicatorDto>;
  /** Get a list of indicators for a given repo */
  getIndicatorsForRepo: Array<IndicatorDto>;
  /** Get the markdown content from a markdown file in a git repo */
  getMarkdown: MarkdownDto;
  /**
   * Get the markdown from a markdown file in a git repo
   * @deprecated The 'query'-part in the name is undesirable. Use `getMarkdown` instead, replace with getMarkdown(input)
   */
  getMarkdownQuery: MarkdownDto;
  /** Get the URL to rendered Owl-content from a git-repository. Triggers rendering the Owl if required. */
  getOwlUrl: OwlDto;
  /** Get the repository info for given input. Info includes latest commit date/ID and the URL/tag for the repo. */
  getRepositoryInfo: GitRepositoryDto;
  /** Get the repository outline for a given input, which can be used to construct the navigation */
  getRepositoryOutline: RepositoryOutlineDto;
  /** Very simple ping-pong call to test basic GraphQL functionality */
  ping: Scalars['String']['output'];
};


export type QueryGetIndicatorByPathArgs = {
  input: GitRemoteInput;
  path: Scalars['String']['input'];
};


export type QueryGetIndicatorsForRepoArgs = {
  input: GitRemoteInput;
};


export type QueryGetMarkdownArgs = {
  input: GitRemoteInput;
};


export type QueryGetMarkdownQueryArgs = {
  input: GitRemoteInput;
};


export type QueryGetOwlUrlArgs = {
  input: GitOwlInput;
};


export type QueryGetRepositoryInfoArgs = {
  input: GitRemoteInput;
};


export type QueryGetRepositoryOutlineArgs = {
  input: GitRemoteInput;
};

export type RepositoryOutlineDto = {
  __typename?: 'RepositoryOutlineDto';
  indexPath?: Maybe<Scalars['String']['output']>;
  items: Array<OutlineItemDto>;
};

export type GetIndicatorsForRepoQueryVariables = Exact<{
  repositoryUrl: Scalars['String']['input'];
  branchOrTag?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetIndicatorsForRepoQuery = { __typename?: 'Query', getIndicatorsForRepo: Array<{ __typename?: 'IndicatorDto', name: string, path: string }> };


export const GetIndicatorsForRepoDocument = gql`
    query GetIndicatorsForRepo($repositoryUrl: String!, $branchOrTag: String) {
  getIndicatorsForRepo(
    input: {repositoryUrl: $repositoryUrl, branchOrTag: $branchOrTag}
  ) {
    name
    path
  }
}
    `;

/**
 * __useGetIndicatorsForRepoQuery__
 *
 * To run a query within a React component, call `useGetIndicatorsForRepoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIndicatorsForRepoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIndicatorsForRepoQuery({
 *   variables: {
 *      repositoryUrl: // value for 'repositoryUrl'
 *      branchOrTag: // value for 'branchOrTag'
 *   },
 * });
 */
export function useGetIndicatorsForRepoQuery(baseOptions: Apollo.QueryHookOptions<GetIndicatorsForRepoQuery, GetIndicatorsForRepoQueryVariables> & ({ variables: GetIndicatorsForRepoQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIndicatorsForRepoQuery, GetIndicatorsForRepoQueryVariables>(GetIndicatorsForRepoDocument, options);
      }
export function useGetIndicatorsForRepoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIndicatorsForRepoQuery, GetIndicatorsForRepoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIndicatorsForRepoQuery, GetIndicatorsForRepoQueryVariables>(GetIndicatorsForRepoDocument, options);
        }
export function useGetIndicatorsForRepoSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetIndicatorsForRepoQuery, GetIndicatorsForRepoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetIndicatorsForRepoQuery, GetIndicatorsForRepoQueryVariables>(GetIndicatorsForRepoDocument, options);
        }
export type GetIndicatorsForRepoQueryHookResult = ReturnType<typeof useGetIndicatorsForRepoQuery>;
export type GetIndicatorsForRepoLazyQueryHookResult = ReturnType<typeof useGetIndicatorsForRepoLazyQuery>;
export type GetIndicatorsForRepoSuspenseQueryHookResult = ReturnType<typeof useGetIndicatorsForRepoSuspenseQuery>;
export type GetIndicatorsForRepoQueryResult = Apollo.QueryResult<GetIndicatorsForRepoQuery, GetIndicatorsForRepoQueryVariables>;