import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import { CredentialResultsDialogProps } from "./CreateHealthcareProviderExcerptResultDialog.types";
import {
    ErrorResultStyling,
    SuccessBox,
} from "./CreateHealthcareProviderExcerptResultDialog.styles";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CreateHealthcareProviderExcerptResultDialog = ({
    open,
    onClose,
    success,
}: CredentialResultsDialogProps) => {
    const { t } = useTranslation("common", {
        keyPrefix: "healthcare-provider-excerpts-create-result",
    });
    const navigate = useNavigate();
    const handleCloseDialog = () => {
        onClose();
        navigate("/healthcare-provider-excerpts");
    };

    return (
        <Dialog
            onClose={handleCloseDialog}
            open={open}
            maxWidth={"md"}
            fullWidth
            data-testid="result"
        >
            <DialogTitle>
                <Typography>{t("title")}</Typography>
            </DialogTitle>
            <DialogContent>
                {success ? (
                    <List
                        subheader={
                            <SuccessBox>
                                <strong>{t("successful")}</strong>
                            </SuccessBox>
                        }
                    />
                ) : null}
                {!success ? (
                    <List
                        subheader={
                            <ErrorResultStyling>
                                <strong>{t("unsuccesful")}</strong>
                            </ErrorResultStyling>
                        }
                    />
                ) : null}
            </DialogContent>
            <DialogActions>
                <Button type="button" onClick={handleCloseDialog}>
                    {t("dismiss")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateHealthcareProviderExcerptResultDialog;
