import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Typography, Alert } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FieldPaper from "src/components/organisms/FieldPaper/FieldPaper";
import { useValidationQuestionProvider } from "../../context/CreateValidatedQuestionContext";
import { VersionPropTypes } from "./Version.types";

const Indicators = ({ step }: VersionPropTypes) => {
    const validatedQuestions = useValidationQuestionProvider();
    const { t } = useTranslation();

    const {
        register,
        formState: { errors },
    } = useFormContext();

    return (
        <FieldPaper
            title={
                <Typography variant="h6">
                    {step}. {t("validated-question-create.version.title")}
                </Typography>
            }
        >
            {validatedQuestions.profile ? (
                <FormControl fullWidth error={Boolean(errors["version"])}>
                    <Select
                        data-testid="version"
                        defaultValue={""}
                        size="small"
                        {...register("version")}
                    >
                        {validatedQuestions.profile?.tagsAndBranches.map(
                            (item) => (
                                <MenuItem
                                    value={item.tagOrBranch}
                                    key={item.tagOrBranch}
                                >
                                    {item.tagOrBranch}
                                </MenuItem>
                            )
                        )}
                    </Select>
                    <FormHelperText>
                        <ErrorMessage errors={errors} name="version" />
                    </FormHelperText>
                </FormControl>
            ) : (
                <Alert severity="info">
                    {t("validated-question-create.version.empty-state")}
                </Alert>
            )}
        </FieldPaper>
    );
};

export default Indicators;
