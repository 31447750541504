import {
    ApolloClient,
    ApolloClientOptions,
    ApolloLink,
    InMemoryCache,
} from "@apollo/client";

type Id = string | number;
type Options = Partial<ApolloClientOptions<any>> & {
    link?: ApolloLink;
};
type _ApolloClient = ApolloClient<any>;

type ClientOptions = {
    id?: Id;
    apolloOptions: Options;
};

export class Apollo {
    clients: { [id: string]: _ApolloClient };

    constructor() {
        this.clients = {};
    }

    getClient({ id, apolloOptions }: ClientOptions): _ApolloClient {
        const _id = id || 1;

        const client =
            this.clients[_id] || this.generateClient(_id, apolloOptions);
        return client;
    }

    private generateClient(id: Id, options: Options) {
        const client = new ApolloClient({
            cache: new InMemoryCache({}),
            ssrMode: typeof window === "undefined",
            ...(options ? options : {}),
        });

        this.clients[id] = client;

        return client;
    }
}

export const apollo = new Apollo();
export default apollo;
